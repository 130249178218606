import React from 'react';
import { Template } from '../interfaces/standTemplate.interface';

const defaultContextValue: StandTemplateContextValue = {
    standTemplate: {
        cardTop: {
            background: "#FFFFFF",
            text: "#FFFFFF"
        },
        cardMiddle: {
            background: "#5EC945",
            text: "#FFFFFF"
        },
        cardBottom: {
            background: "#419E2B",
            text: "#FFFFFF"
        },
        firstPosition: ["First Name", "Last Name"],
        secondPosition: "Person Number",
        thirdPosition: "",
        fourthPosition: "Profile"

    },
    setStandTemplate: () => undefined
};
export const defaultValue: Template = defaultContextValue.standTemplate;

type StandTemplateContextValue = {
    standTemplate: Template,
    setStandTemplate: React.Dispatch<React.SetStateAction<Template>>
}

export const StandTemplateContext = React.createContext<StandTemplateContextValue>(defaultContextValue);

export const StandTemplateProvider = ({ children }: any) => {
  const [standTemplate, setStandTemplate] = React.useState<Template>(defaultValue);

  return (
    <StandTemplateContext.Provider value={{ standTemplate, setStandTemplate }}>
      {children}
    </StandTemplateContext.Provider>
  );
};
