import * as React from "react";
import { Toast } from "native-base";
import * as Notifications from "expo-notifications";
import * as Api from "../../services/api";
import * as Cache from "../../services/cache";
import CARD_STATUS, { CardStatus } from "../../constants/card-status";
import { useAuth } from "../auth/hooks";
import { safeMergeValues } from "../../utils";
import { useTranslation } from "../translations";
import { CameraCapturedPicture } from "expo-camera";
import { ImageInfo } from "expo-image-picker/build/ImagePicker.types";
import { Image, Platform } from "react-native";
import EXIF from "exif-js";
import { View, Text } from "../../components/Styled";
import { manipulateAsync } from 'expo-image-manipulator';
import data from '../../app.json'
import CACHE from "./constants/cache";
import { CardHolderData, CardsPerType, ConfigType, CredentialsType, DigitalCard, NotNormalizedData, NotificationsType, OrganizationType, ReducerActions, StartupData, StartupMethods, StartupProfileType, StartupState } from "./types";
import ACTIONS from "./constants/actions";

function normalize(data: NotNormalizedData): StartupData {
  const correctCardStatus: CardStatus =
    data.user.cardStatus === "InProduction"
      ? CARD_STATUS.IN_PRODUCTION
      : (data.user.cardStatus?.toLowerCase() as CardStatus);

  return {
    ...data,
    config: {
      ...safeMergeValues({ cropRatio: 1 }, data.config)
    } as ConfigType,
    user: {
      ...(data?.user || {}),
      cardStatus: correctCardStatus,
    } as CardHolderData,
  };
}

function reducer(prevState: StartupState, action: ReducerActions) {
  switch (action.type) {
    case ACTIONS.ON_CACHE_LOADED:
      return {
        ...prevState,
        initialized: true,
        config: action.data.config,
        user: action.data.user,
        organization: action.data.organization,
        notifications: action.data.notifications,
        credentials: action.data.credentials,
        profile: action.data.profile,
        appVersion: action.data.appVersion,
        digitalCard: action.data.digitalCard
      };
    case ACTIONS.ON_REQUEST:
      return {
        ...prevState,
        isLoading: true,
      };
    case ACTIONS.ON_RESPONSE:
      return {
        ...prevState,
        isLoading: false,
        config: safeMergeValues(
          prevState.config,
          action.data.config
        ) as ConfigType,
        user: safeMergeValues(
          prevState.user,
          action.data.user
        ) as CardHolderData,
        organization: safeMergeValues(
          prevState.organization,
          action.data.organization
        ) as OrganizationType,
        notifications: safeMergeValues(
          prevState.notifications,
          action.data.notifications
        ) as NotificationsType,
        credentials: safeMergeValues(
          prevState.credentials,
          action.data.credentials
        ) as CredentialsType,
        profile: safeMergeValues(
          prevState.profile,
          action.data.profile
        ) as StartupProfileType,
        cardsPerType: safeMergeValues(
          prevState.cardsPerType,
          action.data.cardsPerType
        ) as CardsPerType,
        appVersion: safeMergeValues(
          prevState.appVersion,
          action.data.appVersion
        ) as string,
        digitalCard: safeMergeValues(
          prevState.digitalCard,
          action.data.digitalCard
        ) as DigitalCard
      };
    case ACTIONS.ON_ERROR:
      return {
        ...prevState,
        isLoading: false,
        error: action.error,
      };
    default:
      return prevState;
  }
}

const initialState = {
  initialized: false,
  isLoading: false,
  config: null,
  organization: null,
  notifications: null,
  credentials: null,
  user: null,
  profile: null,
  cardsPerType: null,
  appVersion: null,
  digitalCard: null
};

export type StartupContextValue = [StartupState, StartupMethods];

const initialContextValue: StartupContextValue = [
  initialState,
  {
    updateStatus: () => undefined,
    updatePhoto: () => undefined,
    markNotificationRead: () => undefined,
    deleteNotification: () => undefined,
    reload: () => undefined,
    updateDigitalCard: () => undefined
  },
];

export const StartupContext =
  React.createContext<StartupContextValue>(initialContextValue);

const StartupProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { refreshAuth, signOut } = useAuth();
  const { t } = useTranslation();

  async function request() {
    try {
      await refreshAuth();
      const res = await Api.getStartupData();
      const profiles = await Api.getUserProfiles(res?.data?.user?.personId);
      res.data.user.profiles = profiles.data;
      
      dispatch({
        type: ACTIONS.ON_RESPONSE,
        data: normalize(res.data),
      });
    } catch (error) {
      if (error?.code === 'invalid_grant') {
        signOut()
      }
      console.warn("Failed requesting startup data", error);

      Toast.show({
        accessible: true,
        accessibilityLabel: "Failed requesting startup data",
        duration: 8000,
        render: () => <View bgColor="orange" style={{ padding: 10 }}>
          <Text color="white">{"Failed requesting startup data"}</Text>
        </View>
      })
      dispatch({ type: ACTIONS.ON_ERROR, error });
    }
  }
  
  React.useEffect(() => {
    async function loadCache() {
      const [user, config, organization, notifications, credentials, profile, appVersion, digitalCard] = await Cache.getMany([
        CACHE.CARD_DATA,
        CACHE.CONFIG_DATA,
        CACHE.ORGANIZATION_DATA,
        CACHE.NOTIFICATIONS_DATA,
        CACHE.CREDENTIALS_DATA,
        CACHE.STARTUP_PROFILE,
        CACHE.APP_VERSION,
        CACHE.DIGITAL_CARD
      ]);
 
      const cachedData: StartupData = {
        user,
        config,
        organization,
        notifications,
        credentials,
        profile,
        cardsPerType: null,
        appVersion,
        digitalCard
      };

      dispatch({
        type: ACTIONS.ON_CACHE_LOADED,
        data: cachedData,
      });
    }

    if (!state.initialized) {
      loadCache();
    } else if (!state.isLoading) {
      request();
      dispatch({ type: ACTIONS.ON_REQUEST });
    }
  }, [state.initialized]);

  React.useEffect(() => {
    async function cacheData() {
      await Cache.setMany([
        [CACHE.CARD_DATA, JSON.stringify(state.user || "")],
        [CACHE.CONFIG_DATA, JSON.stringify(state.config || "")],
        [CACHE.ORGANIZATION_DATA, JSON.stringify(state.organization || "")],
        [CACHE.NOTIFICATIONS_DATA, JSON.stringify(state.notifications || "")],
        [CACHE.CREDENTIALS_DATA, JSON.stringify(state.credentials || "")],
        [CACHE.STARTUP_PROFILE, JSON.stringify(state.profile || "")],
        [CACHE.APP_VERSION, JSON.stringify(data.expo.version || "")]
      ]); 
      await Notifications.setBadgeCountAsync(
        state.notifications?.unreadCount || 0
      );
    }
    if (state.initialized) cacheData();
  }, [state]);

  const markNotificationRead = React.useCallback(
    async (id: string) => {
      try {
        await Api.markAsRead(id);
        dispatch({
          type: ACTIONS.ON_RESPONSE,
          data: {
            ...state,
            notifications: {
              unreadCount: state.notifications?.unreadCount
                ? state.notifications?.unreadCount - 1
                : 0,
            },
          },
        });
      } catch (err) {
        console.warn(
          "Failed marking notification as read",
          err?.response?.data || err
        );
      }
    },
    [state.notifications]
  );

  const deleteNotification = React.useCallback(
    async (id: string) => {
      try {
        await Api.deleteNotification(id);
        dispatch({
          type: ACTIONS.ON_RESPONSE,
          data: {
            ...state,
          },
        });
      } catch (err) {
        console.warn(
          "Failed to delete notification",
          err?.response?.data || err
        );
      }
    },
    [state.notifications]
  );

  const updatePhoto = React.useCallback(
    async (file: CameraCapturedPicture | ImageInfo, approved: boolean) => new Promise(async resolve => {
      const CACHE_USER_DATA = "user";

      // dispatch({ type: ON_REQUEST });
      var upload = file?.base64 || file?.uri;

      const fileSize = upload.length * (3 / 4) - 2;

      if (fileSize > 6000000 && file.uri) {
        Image.getSize(file.uri, async (width, height) => {

          const croppedImage = await manipulateAsync(
            file.uri,
            [
              {
                resize: {
                  width: width - width * 0.5,
                  height: height - height * 0.5,
                },
              },
            ],
            {
              compress: 0.7,
            },
          )

          upload = croppedImage.base64 || croppedImage.uri;
        });
      }

      function correctFileOrientation(photoOrientation?: number) {
        if (Platform.OS === "android") {
          return photoOrientation;
        }

        let finalRotation = photoOrientation || 0;

        if (finalRotation > 0) {
          while (finalRotation > 360) {
            finalRotation -= 360;
          }
        } else if (finalRotation < 0) {
          while (finalRotation < -360) {
            finalRotation += 360;
          }
        }

        switch (finalRotation) {
          case -90:
          case -270:
            return 6;
          case 180:
          case -180:
            return 3;
          case 90:
          case 270:
            return 8;
          default:
            return 1;
        }
      }

      const base64ToArrayBuffer = (base64: string) => {
        var binaryString = atob(base64);
        var len = binaryString.length;
        var bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes.buffer;
      };


      const getOrientationFromBase64 = (base64: string) => {
        let exif;
        try {
          let arrayBuffer = base64ToArrayBuffer(
            base64.slice(base64.indexOf(",") + 1)
          );
          exif = EXIF.readFromBinaryFile(arrayBuffer);
        } catch (error) {
          console.log("Unable to parse base64", error);
        }
        return exif?.Orientation;
      };

      const orientation =
        file.exif !== undefined
          ? correctFileOrientation(file.exif.Orientation)
          : getOrientationFromBase64(upload);


      try {
        await refreshAuth();
        const res = await Api.updatePhoto({
          orientation: orientation ?? 1,
          photo: upload,
          approved,
        });

        const photo = res.data.content ? res.data.content : upload;

        const cacheddata = await Cache.get("user");

        resolve("success")
      } catch (error) {
        console.log("error", error?.message)
        if (error.response?.data?.errors?.faceMatch) {
          error.response.data.errors.faceMatch.forEach((title: string) => {
            resolve(title)
          });
        } else {
          resolve(error?.message ? error?.message : t("profile.photo_edit.failed"))
        }
      }
    }),
    [state, dispatch]
  );

  const updateStatus = React.useCallback(
    async (status) => {
      if (!state.user?.cardId || !status) {
        return;
      }
      try {
        await refreshAuth();
        const res = await Api.udateCardStatus(state.user.cardId, {
          status,
        });

        if (res.data.status === status) {
          Toast.show({
            accessible: true,
            accessibilityLabel: t("digital_id.update_status.success"),
            duration: 8000,
            render: () => <View bgColor="green" style={{ padding: 10 }}>
              <Text color="white">{t("digital_id.update_status.success")}</Text>
            </View>
          })

          dispatch({
            type: ACTIONS.ON_RESPONSE,
            data: {
              ...state,
              user: {
                ...state.user,
                cardStatus: res.data.status,
              },
            },
          });
        } else {
          Toast.show({
            accessible: true,
            accessibilityLabel: t("digital_id.update_status.error"),
            duration: 8000,
            render: () => <View bgColor="orange" style={{ padding: 10 }}>
              <Text color="white">{t("digital_id.update_status.error")}</Text>
            </View>
          })
        }
      } catch (error) {
        Toast.show({
          accessible: true,
          accessibilityLabel: t("digital_id.update_status.error"),
          duration: 8000,
          render: () => <View bgColor="orange" style={{ padding: 10 }}>
            <Text color="white">{t("digital_id.update_status.error")}</Text>
          </View>
        })
        console.warn("Failed updating card status", error.response.data);
      }
    },
    [state.user]
  );

  const updateDigitalCard = React.useCallback(
    async (digitalCard: DigitalCard) => {
      await Cache.set(CACHE.DIGITAL_CARD, JSON.stringify(state.digitalCard));

      dispatch({
        type: ACTIONS.ON_RESPONSE,
        data: {
          ...state,
          digitalCard: digitalCard}
      })
    },
    [state.digitalCard]
  );

  const value: StartupContextValue = React.useMemo(
    () => [
      state,
      {
        updateStatus,
        markNotificationRead,
        deleteNotification,
        reload: request,
        updatePhoto,
        updateDigitalCard
      },
    ],
    [state, updateStatus, markNotificationRead, deleteNotification, request, updateDigitalCard]
  );

  return (
    <StartupContext.Provider value={value}>
      {typeof children === "function" ? children(state) : children}
    </StartupContext.Provider>
  );
};

export default StartupProvider;
