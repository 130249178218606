import Axios from "axios";
import { getBaseApiUrl } from "../constants/base-urls";
import { ApiAddress, UserProfileData } from "../contexts/card-holder";
import { CardHolderData } from "../contexts/startup";
import { cleanBase64 } from "../utils";
import * as Cache from "../services/cache";
import { StandTemplate } from "../interfaces/standTemplate.interface";

Axios.defaults.headers["Content-Type"] = "application/json";

Cache.get("appVersion").then((data) => {
    Axios.defaults.headers.common["Stand-App-Version"] = data;
});

Axios.defaults.baseURL = getBaseApiUrl();

export async function setBaseURL(baseURL: string) {
    if (baseURL) {
        Axios.defaults.baseURL = baseURL;
    }
}

export function setAuthorizationHeader(accessToken: string | null) {
    if (accessToken) {
        Axios.defaults.headers.Authorization = `Bearer ${accessToken}`;
    } else {
        Axios.defaults.headers.Authorization = null;
    }
}

export async function getStartupData() {
    const response = Axios.get(`/api/v2/stand/startup`);
    console.log(response, "startup response");
    return response;
}

export async function updatePersonLanguage(
    langCode: string,
    personId?: string
) {
    return Axios.put(`/api/v2/stand/language/${personId}`, langCode);
}

export async function updateDefaultProfile(data: {}) {
    return Axios.post(`/api/v2/stand/savedefaultprofile`, data);
}

export async function updateProfilePreference(data: {}) {
    return Axios.put(`/api/v2/updateProfilePreference`, data);
}

export async function getCardHolderInfo() {
    return Axios.get(`/api/v2/stand/user`);
}

export async function getNewCardHolderInfo(profileId: string) {
    return Axios.get(`/api/v2/stand/user?switchProfile=${profileId}`);
}

export async function updateUser(data: Partial<UserProfileData>) {
    return Axios.put(`/api/v2/stand/user`, data);
}

export async function updateCardHolder(cardholderid: string, data: any) {
    return Axios.put(`/api/v2/stand/${cardholderid}`, data);
}

export async function getEnumOptions() {
    // return Axios.post(`/api/v2/admincenter/setup/stand/standgeneralsettings/enumOptions`, {attribute : "location"});
    return Axios.get(`/api/v2/stand/enumOptions`);
}

export async function getWizardSettings() {
    return Axios.get(
        `/api/v2/admincenter/setup/stand/standgeneralsettings/wizard`
    );
}

export async function getDetailsField() {
    return Axios.get(
        `/api/v2/admincenter/setup/stand/standgeneralsettings/detailsField`
    );
}

export async function getAllThemes() {
    return Axios.get(`/api/v2/admincenter/setup/stand/standgeneralsettings`);
}

export async function getSystemLabels() {
    return Axios.get(`/api/v1/system-labels`);
}

export type UpdatePhotoParams = {
    photo: string;
    orientation?: string | number;
    approved?: boolean;
};

export async function updatePhoto({
    photo,
    orientation = "0",
    approved = false,
}: UpdatePhotoParams) {
    return Axios.post(`/api/v2/stand/photos/update`, {
        content: cleanBase64(photo),
        orientation,
        approved,
    });
}

export type UpdateCardPayload = {
    status: CardHolderData["cardStatus"];
};

export async function udateCardStatus(
    cardId: string,
    payload: UpdateCardPayload
) {
    return Axios.post(`/api/v2/stand/cards/${cardId}/${payload.status}`);
}

export type CrateCardPayload = {
    cardholder: CardHolderData["cardholderId"];
};

export async function createCard(payload: CrateCardPayload) {
    return Axios.post(`/api/v2/stand/cards`, payload);
}

export async function changePassword(
    currentPassword: string,
    newPassword: string
) {
    return Axios.post(`/api/v2/stand/users/current/password`, {
        currentPassword,
        newPassword,
    });
}

export async function uploadIdentity({ file }: { file: string }) {
    return Axios.post(`/api/v2/stand/documents/identity`, {
        content: cleanBase64(file),
    });
}

export async function getSignature() {
    return Axios.get(`/api/v2/stand/signatures`);
}

export async function uploadSignature({ file }: { file: string }) {
    return Axios.post(`/api/v2/stand/signatures`, {
        content: cleanBase64(file),
    });
}

// tslint:disable-next-line:no-shadowed-variable
export async function pushToken(pushToken: string) {
    return Axios.post(`/api/v2/stand/userdevicepushtokens`, {
        pushToken,
    });
}

// tslint:disable-next-line:no-shadowed-variable
export async function deletePushToken(pushToken: string) {
    return Axios.delete(`/api/v2/stand/userdevicepushtokens/${pushToken}`);
}

export async function getNotifications() {
    return Axios.get("/api/v2/notifications?orderby=StatusRead&ascending=true");
}

export async function getNewCredential() {
    return Axios.get("api/v2/notifications/newCredential");
}

export async function getCardCredentials(cardHolderId: string) {
    return Axios.post(`/api/v1/cards/credentials`, {
        cardholderIds: [cardHolderId],
    });
}

export async function markAsRead(id: string) {
    return Axios.post(`/api/v2/notifications/${id}/markAsRead`);
}

export async function deleteNotification(id: string) {
    return Axios.delete(`/api/v2/notifications/${id}`);
}

export async function getAddresses() {
    return Axios.get(`/api/v1/addresses`);
}

export async function getUserProfiles(personId: string) {
    return Axios.get(`/api/v1/cardholders?person=${personId}`);
}
// API V1 person / Id Put/Post
// https://localhost:5000/api/v1/persons/f25db144-4479-4337-c069-08db81ec8520

export async function updateUserPreference(personId: string, data: any) {
    return Axios.post(`/api/v1/persons/${personId}`, data);
}

export type AddAddressPayload = Omit<
    ApiAddress,
    "created" | "id" | "metadata" | "object" | "type"
>;

export async function addAddress(payload: AddAddressPayload) {
    return Axios.post(`/api/v2/stand/user/address`, payload);
}

export type UpdateAddressPayload = ApiAddress;

export async function updateAddress(payload: UpdateAddressPayload) {
    return Axios.put(`/api/v2/stand/user/address`, payload);
}

export async function updateCardStatus(
    cardId: string,
    status: string,
    typeId: string,
    cardholderId: string,
    personId: string,
    profileId: string,
    startDate: Date,
    endDate: Date
) {
    return Axios({
        method: "post",
        url: `/api/v1/cards/updatePerType`,
        data: {
            cardId: cardId,
            status: status,
            type: typeId,
            cardholderId: cardholderId,
            personId: personId,
            profileId: profileId,
            startDate: startDate,
            endDate: endDate,
        },
        validateStatus: function (status) {
            return status <= 500;
        },
    });
}

export async function getCard(cardId: string) {
    return Axios.get(`/api/v1/cards/${cardId}`);
}

export async function getTemplateByProfileId(profileId: string): Promise<StandTemplate> {
    var response = await Axios.get<StandTemplate>(`/api/v2/admincenter/setup/stand/standTemplates/${profileId}`);
    return response.data;
}